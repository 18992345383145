<template>
  <div></div> 
</template>

<script>
export default {
  created () {
    this.$router.push('/legal/request/list')
  }
}
</script>

<style>

</style>
